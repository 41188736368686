@use "_colors";
@use '_fonts';


.gridjustify {
    text-align: center !important;
    
}

.sidebarresp {
    width: 16.25rem;
    flex-shrink: 0;
    &.mini {
        width: 5rem;
    }
}

.drawer {
    &.MuiPaper-root {
        overflow: auto;
        background-color: colors.$green;
        width: 16.25rem;
        align-items: center;
        color: colors.$white;
        overflow-x: hidden;
        border-right: 0;
        z-index: 3;
        height: 100%;
        box-sizing: 'border-box';
        line-height: 1.4285em;
    }
    & .MuiList-root {
        width: 100%;
        
    }

    & .MuiListItem-root {
        flex-direction: column;
        
    }

    &.mini {
        &.MuiPaper-root {
            width: 5rem;
        }
        & .MuiListItem-root {
            padding-bottom: 0.5rem;
        }

        & .MuiList-root {
            padding-top: 0;
        }
    }
}

.miniAvatarCenter {
        padding-top: 1rem;
        padding-bottom: 2.8125rem;
}

.restBox {
    margin-top: 3rem;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.iconButton {
    & .MuiListItemIcon-root {
        justify-content: center;
        
    }

    & .MuiListItemButton-root {
        width: 2.5rem;
        word-wrap: break-word;
        justify-content: center;
        border-radius: 0.5rem;
        margin: 0 auto;
        line-height: 1.4285em;
    &.active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), colors.$green;
    }
    }

}

.tooltipStyle {
    & .MuiTooltip-tooltip {
        margin: 0 !important;
        border-radius: 0 0.5rem 0.5rem 0;
        padding: 0;
        overflow-x: hidden;
    }
    &.onlyTip {
        & .MuiTooltip-tooltip {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), colors.$green;
            padding-right: 1.25rem;
        }
    }
}

.fullWidthPopper {
    width: 100%;
}

.listItemStyling {
    & .MuiTypography-root {
        @include fonts.paraRegular;
    }
    & .MuiListItemIcon-root {
        min-width: 1.5rem;
        margin-right: 1rem;
    }

    & .MuiCollapse-root {
        margin-top: 0.75rem;
    }
}

.buttonStyling {
    &.MuiListItemButton-root {
        width: 14.25rem;
        height: 2.5rem;
        top: 1.25rem;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;

        & .MuiTypography-root {
            @include fonts.buttonNormal;
            
        }
        &:hover {
            color: colors.$white;
            background: rgba(0, 0, 0, 0.1);
        }


    }
    &.defActive, &.defActive:hover {
        background: rgba(0, 0, 0, 0.3);
        
    }
    &.dropdownButton, &.dropdownButton:hover {
        border-radius: 0.5rem 0.5rem 0px 0px;
        background: rgba(0, 0, 0, 0.3);         
    }
}

.sideBarCollapse {

    &.MuiButton-root {
        margin-right: 1.5rem;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        min-width: 2.5rem;
        min-height: 2.5rem;
        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }
        &:active {
            background: rgba(0, 0, 0, 0.3);           
        }
    }

    &.miniResp {
        @media screen and (max-height: 886px) {
            margin:auto;
            margin-bottom: 1rem;
        }
    }

}

.nestedButtonStyling {
    
    &.MuiListItemButton-root {
        
    min-height: 2rem;
    width: 14.25rem;
    padding-left: 0 !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), colors.$green;
    color: colors.$white;
     &:focus {
        background-color: colors.$green_darkest;
    }
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.4);
    }
    &.active {
        background-color: rgba(0, 0, 0, 0.45);
    }
}
& .MuiListItemIcon-root {
    min-width: 0;
    margin-right: 0;
        
}

& .MuiListItemText-root {
    padding-left: 1.25rem;
} 

&.collapseHover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), colors.$green;
    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), colors.$green;
    }
    &.collapseActive {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), colors.$green;
        &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), colors.$green;
        }
        &:active, &:focus {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), colors.$green;
        }
    }
}

}

.sidebarDropper {
    &.MuiList-root {
    width: 14.25rem;
    overflow-x: hidden;
    border-radius: 0px 0px 0.5rem 0.5rem;
    }

}
.collapselistStyling {
    &.MuiList-root {
        padding: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), colors.$green;
    }
    &.active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), colors.$green !important;
    }
}

.title {
    &.MuiListItem-root {
    height: 2.5rem;
    padding-left: 1.25rem;
    }

    &.isClick  {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), colors.$green;
    }
}

.logolink {
    cursor: pointer;
}

.logo {
    padding-top: 1rem;
    padding-bottom: 2.625rem;
}
.avatar {
    &.MuiAvatar-root {
        height: 7.5rem;
        width: 7.5rem;
    }
    margin: auto;
    margin-bottom: 1.25rem;
}

.fonts {
    pointer-events: none;
    
    &.name {
        @include fonts.medH4;
        padding-bottom: 0.5rem;
    }
    &.email {
        @include fonts.paraRegular;
        max-width: 75%;
        overflow-x: clip;
        overflow-wrap: break-word;
        text-align: center;
        margin: auto;
    }
    &.nestedTitle {
        @include fonts.medPara;
    }
    &.btnNormal {
        @include fonts.buttonNormal;
    }
}

  span.sizer {
    line-height: 1.21428571em;
    padding: 0.67857143em 2.1em 0.67857143em 1em;
    display: none;
    white-space: pre;}

    html,
body {
  height: 100%;
}

html {
  font-size: 14px;
}

body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  min-width: 320px;
  background: #FFFFFF;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.87);
}