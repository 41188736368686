@use '_colors';
@use '_fonts';

*,
*:before,
*:after {
  box-sizing: inherit;
}

.base {

    &.MuiToolbar-root {
        height: 3.5rem;
        min-height: 3.5rem;
        background-color: colors.$gray_light;
        max-width: 120rem;
        
    }
}
.crumbpadding {
    padding-left: 4rem;
    @media (max-width: 48rem) {
        padding-left: 0.5rem;
    }
}
.activecrumb {
    &.MuiTypography-root{
        @include fonts.paraCaption;
        color: colors.$blue;
        text-decoration: none;
        &:hover {
            color: colors.$blue_bright;
        }
    }
}

.lineheight {
    &.MuiBreadcrumbs-root
    {
        @include fonts.paraCaption;
        color: colors.$gray_dark;
        
    }
}
.basecrumb {
    &.MuiTypography-root{
        line-height: 0;
        @include fonts.paraCaption;
        color: colors.$gray_dark;
        text-decoration: underline;
        }
}
.zindex {
    &.MuiPaper-root {
        background-color: colors.$gray_light;
        z-index: 3;
    }
}

.breadFonts {
    &.MuiTypography-root{
    @include fonts.paraCaption;
    color: colors.$gray_dark;
    }
    &.active {
        &.MuiTypography-root {
        @include fonts.medCaption;
    }
    }
}

.boxpadding {
    padding-right: 2rem;
    @media (max-width: 48rem) {
        padding-right: 0.5rem;
    }
    &.logbox {
        padding-right: 4rem;
        white-space: nowrap; 
        width: 9.5rem;
        display: flex;
        justify-content: flex-end;
        @media (max-width: 48rem) {
        width: 6.25rem;
        padding-right: 0.5rem;
        }
    }
}