@use "_colors";
@use "_fonts";

.developmentPage {
    background-color: colors.$gray_light;
    .contentContainer {
        max-width: 100rem;
        .sectionTitle {
            @include fonts.medH3;
            color: colors.$gray_dark;
            padding: 2rem 0rem;
        }
        .jodit-container {
            color: colors.$gray_dark;
            @include fonts.paraCaption;
            p {
                margin: 0 !important;
            }
        }
        .topic {
            padding: 0.75rem 1rem;
            p {
                color: colors.$gray_dark;
                @include fonts.paraRegular;
                margin: 0 !important;
            }
        }
        .accordionContent {
            .noContent {
                text-align: center;
                @include fonts.medPara;
                padding: 2rem 1rem 1rem 1rem;
            }
            .addCommentsDiv {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 1rem 3rem 1rem 1rem;
                @include fonts.medPara;
                color: colors.$gray_dark;
            }
            .newCommentDiv {
                padding: 1rem 2rem 1rem 1rem;
                border-top: 1px solid colors.$gray;
                .newCommentButtons {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    padding-top: 1rem;
                    .hideCommentButton {
                        padding-right: 0.5rem;
                    }
                }
            }
            .commentSection {
                color: colors.$gray_dark;
                @include fonts.paraCaption;
                padding: 0.75rem 2rem 0.75rem 1rem;
                word-break: break-all;
                text-align: justify;
                text-justify: inter-word;
                .singleComment {
                    padding: 0.75rem 0rem;
                }
            }
            .modifyButtonDiv {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding: 1rem 2rem;
                .deleteButton {
                    padding: 0rem 3.5rem 0rem 0rem;
                }
                .modifyButton {
                    padding-right: 1rem;
                }
            }
            .markReadyDiv {
                border-top: 1px solid colors.$gray;
                text-align: center;
                padding: 1rem 0rem;
                .markReadyHeader {
                    color: colors.$blue;
                    @include fonts.medPara;
                    padding-bottom: 0.5rem;
                }
                .markReadyButtons {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    .cancelMarkReadyButton {
                        padding-right: 0.5rem;
                    }
                }
            }
            .confirmDiv {
                text-align: center;
                padding: 1rem 0rem;
                .confirmHeader {
                    color: colors.$blue;
                    @include fonts.medPara;
                    padding-bottom: 0.5rem;
                }
                .confirmButtons {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    .cancelConfirmButton {
                        padding-right: 0.5rem;
                    }
                }
            }
            .commentPadding {
                padding-bottom: 1rem;
            }
            .addGoalDiv {
                text-align: right;
                padding: 1.5rem 2rem;
            }
            .grayDivider {
                border-bottom: 1px solid colors.$gray;
            }
        }
    }
}


.scrollPadding {
    scroll-margin: 7rem;
}


.loaderDiv {
    text-align: center;
    background-color: white;
    padding: 2rem 0rem;
}

.modifyCF {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem 2rem;
}

.spacediv {
    height: 2.5rem;
}
