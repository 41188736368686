@use '_colors.scss';
@use '_fonts.scss';

.loadmore {
    &__content {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 1rem;
        &--text {
            @include fonts.paraCaption;
        }
    }
}