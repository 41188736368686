@mixin firaSans {
  font-family: "Fira Sans";
}

@mixin captionLight {
    @include firaSans;
    font-size: 0.8313rem;
    font-weight: 300;
    letter-spacing: 0.04em;
    line-height: 1rem;
  };
@mixin labelLight {
  @include firaSans;
    font-size: 0.6944rem;
    font-weight: 300;
    letter-spacing: 0.04em;
    line-height: 1rem;
  };
@mixin paraRegular {
  @include firaSans;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 150%;
    
  };
@mixin paraCaption {
  @include firaSans;
    font-size: 0.8313rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 1rem;
  };
@mixin paraLabel {
  @include firaSans;
    font-size: 0.6944rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 0.8125rem;
  };
@mixin medH1 {
  @include firaSans;
    font-size: 2.075rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 130%;

  };
@mixin medH2 {
  @include firaSans;
    font-size: 1.725rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 130%;
  };
@mixin medH3 {
  @include firaSans;
    font-size: 1.4375rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 130%;
  };
@mixin medH4 {
  @include firaSans;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 130%;
  };
@mixin medPara {
  @include firaSans;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 150%;
  };
@mixin medCaption {
  @include firaSans;
    font-size: 0.8313rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 1rem;
  };
@mixin medLabel {
  @include firaSans;
    font-size: 0.6937rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 0.8125rem;
  };
@mixin boldH1 {
  @include firaSans;
    font-size: 2.075rem;
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 130%;
  };
@mixin boldH2 {
  @include firaSans;
    font-size: 1.725rem;
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 130%;
  };
@mixin boldH3 {
  @include firaSans;
    font-size: 1.4375rem;
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 130%;
  };
@mixin boldH4 {
  @include firaSans;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 150%;
  };

  @mixin buttonSmall {
    @include firaSans;
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.0288rem;
  }

  @mixin buttonNormal {
    @include firaSans;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2rem;
    letter-spacing: 0.04em;
  }

  @mixin buttonLarge {
    @include firaSans;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.625rem;
    letter-spacing: 0.0288rem;
  }
  