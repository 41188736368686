@use "_colors";
@use "_fonts";

.helpertext {
    @include fonts.medCaption;
    color: colors.$raspberry_dark;
    position: absolute;
}

.datefield {
    width: 100%;
    height: 2rem;
    border-radius: 4px;
    background-color: colors.$white;


    & .MuiButtonBase-root {
        &:hover {
            background-color: colors.$blue_light;
        }
    }

    & .MuiOutlinedInput-root {
        
        @include fonts.paraRegular;
        color: colors.$gray_dark;
        & > fieldset  {
            border: 0.5px solid colors.$gray_dark;
        }

        &.Mui-focused {
            & > fieldset {
                    border: 0.5px solid colors.$blue_bright;
            }
        }

        &.Mui-error {
            & > fieldset {
                border-color: colors.$raspberry_dark;
            }
            &:hover {
                & > fieldset {
                border-color: colors.$raspberry_dark;
                }
            }
            color: colors.$raspberry_dark;
        }
        &:hover {
            & > fieldset {
                border: 1px solid colors.$blue;
        }
        }
        &.Mui-disabled {
            background-color: colors.$gray;
            & > fieldset {
                border: 0;
        }
        }
    }
    & .MuiInputBase-root {
       height: 2rem; 

       & .Mui-disabled {
        -webkit-text-fill-color: colors.$gray_dark;
    }
    }

     & .MuiFormHelperText-root.Mui-error {
         color: colors.$raspberry_dark;
     }
}

.datepicker {
    &.MuiPaper-root {
    background-color: colors.$white;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;

}
    & .MuiButtonBase-root {
        @include fonts.paraCaption;
        &.Mui-selected {
        background-color: colors.$blue_bright;
    }
        &:hover, &:focus {
            background-color: colors.$blue_light;
        }



    }

    & .MuiPickersCalendarHeader-label {
            font-size: 1rem !important;
        @include fonts.medCaption;
        color: colors.$blue;
        
    }

    & .css-1v994a0 {
        @include fonts.medPara;
        color: colors.$blue;
    }

    


}


.typoLabelDate {
    @include fonts.medCaption;
    color: colors.$blue;
    &.error {
        color: colors.$raspberry_dark;
    }
    &.disabled {
        color:colors.$gray_dark;
    }
}