@use '_colors';
@use '_fonts';

.searchfield {
    width: 13.75rem;
    height: 2rem;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    border-radius: 1.25rem;
    background-color: colors.$white;
    & .MuiInputBase-input {
        @include fonts.paraRegular;
        width: 9.75rem;
        padding: 0.5rem 0.6875rem 0.5rem 1rem;
        color: colors.$gray_dark;
    &::placeholder{
        color: colors.$gray_darker;
    } 
    &:hover::placeholder  {
        color: colors.$gray_dark;
    }
    &:focus::placeholder {
            color: transparent;
        }
    }
}

.filtering {
    height: 2.5rem;
}
