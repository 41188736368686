@use '_colors';
@use '_fonts';

.checkboxStyling {
    &.MuiButtonBase-root .MuiTouchRipple-child {
        background-color: colors.$blue_medium;

    }
    &.MuiCheckbox-root:hover {
        background-color: colors.$blue_light
    }
    &.MuiButtonBase-root.Mui-disabled {
        color: colors.$gray_dark;
    }  
}

.label {
    @include fonts.paraCaption;
    color: colors.$gray_dark;

    &.leftPadding {
        padding-left: 0.5rem;
    }
}


