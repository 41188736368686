@use '_fonts';
@use '_colors';

.certedu {

    &__personstyle {
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        cursor: pointer;
    }

    &__mainfont {
        @include fonts.medPara;
    }

    &_subfont {
        @include fonts.paraRegular;
    }
    &__headertext {
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        @include fonts.medPara;
        color: colors.$blue;
        padding-top: 0 !important;
    }
}