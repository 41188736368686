@use '_colors';
@use '_fonts';

.footer {
    &.MuiPaper-root {
    top: auto;
    bottom: 0;
    height: 3.75rem;
    background: colors.$gray_dark;
    color: colors.$white;
    z-index: 2;
    
}
}

.centerLinks {
    max-width: 120rem;
    padding-left: 16.25rem;
    justify-content: center;
    
    height: 100%;
    text-align: center;
     & .MuiTypography-root {
        color: colors.$white;
     }

    &.collapsed {
        padding-left: 5rem;
    }
}

.opFont {
    @include fonts.paraRegular;
}
.tsFont {
    @include fonts.paraCaption;
}


.footerPic {
    position: absolute;
    right: 1rem;
    bottom: 1.125rem;
    top: 1.125rem;
}

.displayflex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slackicon {
    display: flex;
    align-items: center;
    margin-right: 0.5rem !important;
}