@use '_colors.scss';
@use '_fonts.scss';

.notfound {
    background-color: colors.$gray_light;
    padding-top: 3.5rem;
    padding-bottom: 3.75rem;
    padding-left: 16.25rem;
    text-align: center;
    &--collapsedsidebar {
        padding-left: 5rem;
    }
    &__content {
        background-color: colors.$white;
        position: relative;
        height: 40rem;
        background-color: colors.$white;
        color: colors.$gray_dark;
        &__background {
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
        &--alignment {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
        &__title {
            font-family: 'Fira Sans';
            font-style: normal;
            text-align: center;
            font-weight: 700;
            font-size: 8.6045rem;
            line-height: 130%;
            letter-spacing: 0.04em;
        }
        &__description {
            @include fonts.medH1;
        }
        &__buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 2rem;
            &--backbutton {
                margin-left: 2.5rem;
            }
        }
    }
}
