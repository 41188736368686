@use '../styles/_colors.scss';
@use '../styles/_fonts.scss';


.profile {
    &--interestplaceholder {
        @include fonts.paraCaption;
    }

    &--pointer {
        cursor: pointer;
    }
    &--form {
        &__formwidth {
            width: 100% !important;
        }
    }


    &--paper
    {

    &__loadingskills {
        height: 25rem;
        width: 100%;
        padding: 1rem;
    }
    
    &__addtitlepadding {
        padding: 1rem !important;
        width: 100% !important;
    }

    &__addformpadding {
        padding: 1rem 2rem !important;
    }

    &__skillpadding {
        padding: 1rem 2rem 1rem 2rem !important;
        width: 100% !important;
    }
    
    &__sidebarwrapper {
        &.MuiPaper-root {
        min-width: 25rem;
        height: 27rem;
        width: auto;
        background: #FFFFFF;
        box-shadow: 0.0625rem 0.1875rem 0.4375rem rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
        padding: 1.5rem;
    }
    }

    &__messageswrapper {
        &.MuiPaper-root {
            min-width: 25rem;
            height: auto;
            width: auto;
            background: #FFFFFF;
            box-shadow: 0.0625rem 0.1875rem 0.4375rem rgba(0, 0, 0, 0.1);
            border-radius: 0.25rem;
            padding: 1.5rem;
            text-align: center;
            white-space: pre-wrap;
            @include fonts.medPara;
        }
    }

    &__messageswrapper {
        &.MuiPaper-root::first-line {
            @include fonts.medH4;
            color: #D1485A;
        }
    }

}


&--grid {
    &__itemleft {
        &.MuiGrid-root {
        margin-left: 1rem;
        }
    }
    &__pagewrapper {
        &.MuiGrid-root {
            height: 100%;
            width: 100%;
            padding: 5.5rem 4.625rem 6.5rem 20.875rem;
        
            }
            &.collapsed {
                &.MuiGrid-root {
                    padding-left: 9.625rem;
                }
            }
    }
    &__profcontainer {
        padding-top: 1rem;
    }

    &__imageposition {
        &.MuiGrid-root {
            position: absolute;
            height: 7.5rem;
            width: 7.5rem;
            right: 1.5rem;
            top: 0;
            border-Radius: 4rem;
        }
    }

    &__profimagepos {
        &.MuiGrid-root {
            position: absolute;
            right: 2.5rem;
            top: 2rem;
        }
    }
    &__profimage {
        &.MuiAvatar-root {
            height: 7.5rem;
            width: 7.5rem;
            margin-right: 1rem;
        }
    }
    &__summary {
        overflow: hidden;
        height: 6.5rem;
    }

    &__languages {
        height: 5.5rem;
        overflow: hidden;
    }
}

&--projecttext {
    @include fonts.paraRegular
}   

&--addnewfont {
    @include fonts.medPara;
    padding-left: 1rem;
    &.nopadding {
        padding-left: 0;
    }
}

&--newprojectheader {
    @include fonts.medH4;
}

&--titlebigfont {
    @include fonts.medH2;
}

&--titlegrayfont {
    @include fonts.medH4;
    color: colors.$gray_dark; 
}

&--breakword {
    @include fonts.paraCaption;
    word-break: break-all;
    white-space: pre-line;
}

&--titlefont {
    @include fonts.medH4;
    color: rgba(0, 0, 0, 1);
    margin: 0;
}


&--titlecaption {
    @include fonts.medCaption;
    color: colors.$blue;

    padding-bottom: 1rem;
    &.lang {
        padding-bottom: 0.5rem;
    }
    &.nopadding {
        padding: 0;
    }
    &.profpadding {
        padding-bottom: 0;

    }
}

&--deptext {
    margin-bottom: 3rem;
    @include fonts.paraCaption;
    color: rgba(0, 0, 0, 1);
    word-break: break-all;
    white-space: pre-line;
    &.multiline {
        margin-bottom: 1rem;
    }
    &.nopadding {
        padding: 0;
    }
    &.profpadding {
        padding-left: 0.5rem;
    }
    &.titleoverflow {
        padding-left: 0.5rem;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &.summaryoverflow {
        padding-left: 0.5rem;
        overflow-y: hidden;
        text-overflow: ellipsis;
    }
}

&--subtitlefont {
    @include fonts.medH4;
    color: rgba(0, 0, 0, 1);
}

&--cert {

    &__headertext {
        @include fonts.medPara;
        color: colors.$blue;

        &.lang {
            padding-bottom: 0.5rem;
        }
    }

    &__regtext {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        @include fonts.paraCaption;
        text-overflow: ellipsis;
        
        & p {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }


}

&--dev {
    &__titlefont {
        @include fonts.medH4;
        color: rgba(0, 0, 0, 1);
        padding-bottom: .5rem;
    }

    &__headertext {
        &.MuiTypography-root {
            @include fonts.medPara;
            color: colors.$blue;
        }
    }

    &__text {
        &.MuiTypography-root {
        @include fonts.paraRegular;
        color: colors.$gray_dark;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}


}
&--neededskills {
    &__text {
        @include fonts.paraCaption;
        padding-bottom: 1rem;
    }
    
    &__hotstar {
        min-width: 1.5rem !important;
    }

}
&--skillitemtext {
    & .MuiTypography-root {
    @include fonts.medCaption;
    color: colors.$gray_dark;
}
}

&--listitemtext {
    & .MuiListItemText-primary {
        @include fonts.medPara;
        color: colors.$blue;
    }
    & .MuiListItemText-secondary {
        @include fonts.paraRegular;
        color: colors.$gray_dark;
    }
    & .MuiTypography-root {
        @include fonts.paraCaption;
        color: colors.$gray_dark;
    }
}

&--listitembtn {

    &__overflow {
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        white-space: normal !important;
    }
    &__style {
        pointer-events: none !important;
        padding-left: 0 !important;
        word-break: break-all;
        white-space: pre-line;
    }

    &__pointerall {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        &.MuiListItemButton-root:hover {
            background-color: transparent !important;
        }
        pointer-events: all !important;
    }
    &__deletebutton {
        pointer-events: all !important;
        z-index: 1;
    }
}

&--box {
    &__posrelative {
        position: relative !important;
    }
}

&--interest {
    &__itemtext {
        & .MuiTypography-root {
            @include fonts.paraCaption;
            color: colors.$gray_dark;
            padding: 1.125rem 0;
        }
        &.MuiListItemText-root {
            margin: 0;
        }
    }
    &__cellitem {
        padding: 0 0.625rem !important;
        pointer-events: all !important;
        &.MuiListItemButton-root:hover {
            background-color: transparent !important;
        }
    }
}

&--form {

    &__divider {
        border: 0.5px dashed colors.$blue;
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 100%;
    }
    &__editdivider {
        margin-top: 1rem;
        margin-bottom: 1rem; 
        border: 0.5px solid colors.$gray;
        width: 100%;
    }
}

&--langchip {
    &__chipstyling {
        &.MuiChip-root {
            background-color: colors.$white;
            @include fonts.paraCaption;
            color: colors.$gray_dark !important;
            border-color: colors.$blue;
            pointer-events: all !important;
            &.MuiChip-clickable:hover{
                background-color: colors.$white;
            }
        }
    }
}

&--loadingcircle {
    display: flex;
    justify-content: center;
    align-items: center;
}
&--notfound {
    @include fonts.medPara;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

&--hoverLine {
    :hover {
    box-sizing: inherit;
    box-shadow: inset 0px -1px 0px #03499E;
    }
}

&--skillstable {
    &__hot {
        @include fonts.medPara;
        font-weight: 500;
    }
    &__reg {
        @include fonts.paraRegular;
    }
    &__hoticon {
        min-width: 0 !important;
        padding-right: 0.5rem;
    }
}
}
