@use '_colors';
@use '_fonts';

.skillButton {
    
    &.MuiIcon-root {
    width: 2rem;
    height: 0.5rem;
    background: colors.$gray;
    border-radius: 4px;
    }



}

.skillStyling {
        & .MuiRating-icon {
        width: 2.5rem;
    }

    & .MuiRating-iconFilled {
        & > .MuiIcon-root {
            background: linear-gradient(180deg, rgba(3, 73, 158, 0) 0%, rgba(3, 73, 158, 0.5) 100%), #0460D1;
        }
    }
    
    & .MuiRating-iconHover {
        & > .MuiIcon-root {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #0460D1;        }
    }
}
.skillLabel {
    &.MuiInputLabel-root {
        padding-bottom: 1rem;
        color: colors.$blue;
        @include fonts.medCaption;
        &.Mui-disabled {
            color: colors.$gray_dark;
        }
        &.Mui-error {
            color: colors.$raspberry_dark;
        }
        & .MuiInputLabel-asterisk {
            &.Mui-error {
                color: colors.$raspberry_dark;
            }
        }
        &.darklabel {
            color: colors.$gray_dark;            
        }
    }
}
