@use '../styles/_colors.scss';
@use '../styles/_fonts.scss';



.ceretedu {

    &--grid {
        &__pagewrap {
            &.MuiGrid-root {
                height: 100%;
                width: 100%;
                padding: 5.5rem 4rem 0rem 20.875rem;
                }
        
                &.collapsed {
                    &.MuiGrid-root {
                        padding-left: 9rem;
                    }
                }
        }
    }
    &--fonttext {
        @include fonts.paraCaption;
        word-break: break-all;


        &.darker {
            color: colors.$gray_darker;
        }
    }
}



