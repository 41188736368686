@use "_colors";
@use "_fonts";

.dropdownstyling {
    min-width: 13.75rem;
    height: 2rem;
    border-radius: 4px;

    &.open {
        border-radius: 4px 4px 0px 0px;
    }

    &.MuiOutlinedInput-root {
        & > fieldset  {
            border: 0.5px solid colors.$gray_dark;
        }

        &:hover {
            & .typoPlace {
            color: colors.$gray_dark;
        }
            & > fieldset {
                border: 1px solid colors.$blue;
        }
        }
    }
    &.MuiOutlinedInput-root.Mui-focused {
        & > fieldset {
            border: 1px solid colors.$blue;
        }
    }
    & .MuiInputBase-root {
       height: 2rem; 
    }
    & .MuiSelect-icon {
        right: 0.75rem;
        top: 0.6875rem;
    }
}


.dropdownmenuStyling {
    margin-top: -4px;
    & .MuiPaper-root {
        border-radius: 0 0 4px 4px;
        border: 1px solid colors.$blue;
        border-top: 0px;
    }

    & .MuiMenuItem-root {
        height: 2rem;
        background-color: colors.$white;
         &:hover, &:focus {
             background-color: colors.$blue_light;
         }
         &.Mui-selected {
            background-color: colors.$blue_medium;
            .typoNormal {
                color: colors.$blue_dark;
            }
        }
    }
}

.typoNormal {
    @include fonts.paraRegular;
    color: colors.$gray_dark;
}

.typoPlace {
    @include fonts.paraRegular;
    color: colors.$gray_darker;
}
.typoLabel {
    @include fonts.medCaption;
    color: colors.$blue;
}
