.gridcontainer {


    &--scrollmargin {
        min-height: 28.4375rem !important;
        scroll-margin-top: 3.25rem !important;
        scroll-margin-bottom: 6.25rem !important;
    }

    &--skillexperts {
        &__margintable {
            max-height: 27rem !important;
            margin: 1rem !important;
            margin-top: 0.5rem !important;
            overflow: auto !important;
            display: flex !important;
            align-content: flex-start !important;
        }

        &__personcontainer {
            height: 3.5rem !important;
            cursor: pointer;   
        }
    
        &__personimage {
            height: 2.5rem !important;
            width: 2.5rem !important;
            border-radius: 4rem !important;
        }
    }

    &--certedu {
        &__tablepadding {
            padding: 1rem !important;
        }
        &__containerpadding {
            padding: 0 0.5rem !important;
        }
        &__addbuttonmargin {
            align-items: center;
            display: flex !important;
            justify-content: flex-end !important;
            pointer-events: none;
            margin-bottom: -4rem !important;
            margin-right: 4rem !important;
        }
        &__flexend {
            justify-content: flex-end;
            margin-bottom: 1rem !important;
        }
    }
   
    &--formcontainer {
        &__addnewpaddings {
            padding: 1rem !important;
        }
        &__itempaddings {
            padding: 0 2rem !important;
        }
        &__lastitempaddings {
            padding: 0 2rem 1rem 2rem !important;
        }
     

        &__sidepaddings {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
        
    }

    &--formcontainer, &--workhistory {
        &__formpadding {
            padding: 1rem 2rem !important;
        }
        &__innerform {
            padding: 0 1rem 1rem 1rem !important;
        }
    }
    &--workhistory {
        &__addnewcontainer {
            justify-content: flex-end !important;
            margin-right: 1rem !important;
        }
    }
    &--projects {
        &__infoform {
            padding: 0 2rem 1rem 2rem !important;
        }
    }

    &--projectmembers {
        max-height: 12.5rem !important;
        overflow-y: auto !important;
    }

    &--myskills {
        &__titlepadding {
            &.none {
                padding-bottom: 0 !important;
            }
            &.one {
                padding-bottom: 1rem !important;
            }
            &.two {
                padding-bottom: 2rem !important;
            }
        }
    }


    &--skillsbuttons {
        &__skillsButtonsContainer {
            justify-content: flex-end !important;
            padding-right: 1rem !important;
            &.nopadding {
                padding-right: 0 !important;
            }
            &.bottompadding {
                padding-bottom: 1rem !important;
            }
        }

    }

    &--addskilltoproject {
        &__skillcontainer {
            min-height: 3.5rem !important;
            align-items: center;
        }

        &__componentcontainer {
            min-height: 22.5rem !important;
        }

        &__addedskills {
            max-height: 20rem !important;
            overflow: auto !important;
        }

        &__hideoverflow {
            display:flex;
            align-items: center;
            overflow: hidden !important;
        }

        &__tablecontainer {
            margin-top: 0 !important;
            justify-content: space-between !important;
            display: flex;
            align-content: flex-start;
            align-items: center;

    }
    }

    &--unapprovedlist {
        margin-top: 1rem;
        padding-left: 2.5rem;
        max-height: 10rem !important;
        word-wrap: break-word !important;
        overflow-y: auto !important;
    }

}

.griditem {

    &--deletebutton {
        display: grid;
        vertical-align: middle;
    }

    &--negativemargin {
        margin-bottom: -3.5rem !important;
    }

    &--projectmembers {
        padding-left: 1rem !important;
    }
    &--bottommargin {
        margin-bottom: 7rem !important;
    }
    &--addbutton {
        z-index: 1 !important;
        pointer-events: all !important;
        &__savebutton {
            pointer-events: all !important;
            position: relative;
            z-index: 1 !important;
        }
        &__editbutton {
            padding: 1rem;
    
        }
        &__removebutton {
            padding-top: 1rem;

        }
    }

    &--skillexperts {
        &__titlecontainer {
            margin-bottom: 1rem !important;
            height: 1.5rem !important;
        }
        &__title {
            z-index: 150 !important;
            background-color: white !important;
            position: sticky !important;
            top: 0;
        }
    }

    &--skilllink {
        &__radiopos {
            margin-left: 0.625rem !important;
            margin-top: -1rem !important;
        }
    }

    &--projclient {
        &__refbox {
            padding-bottom: 2.8125rem !important;
        }
    }


    &--skillsbuttons {
        &__deletebuttonmargin {
            margin-right: 3rem !important;
        }
        &__paddingRight {
            padding-right: 3.5rem !important;
        }
    }

    &--userskill {
    
        &__skilltopmargin {
            margin-top: 2.8125rem !important;
        }

        &__buttonmargin {
            margin-top: 3.8125rem !important;
        }
    }

    &--addskilltoproject {
        &__titlecontainer {
            margin-bottom: 1rem !important;
        }

        &__summarycontainer {
            height: 7.1875rem !important;
            overflow: auto !important;
        }
    }

    &--certedu {
        &__publicationpadding {
            padding: .5rem 16px 0rem 0rem !important;

        }
        &__accordionpadding {

            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
        }
        &__pubtextpadding {
            padding-left: .5rem;
        }
    
    }

    &--texteditor {
        padding-right: 16px;
    }

    &--formitem {

        &__radiomargin {
            margin-top: 0.625rem !important;
        }

        &__titlemargin {
            margin-bottom: 1rem !important;
        }

        &__itemtitlemargin {
            margin-top: 1rem !important;
        }
        &__papermargin {
            margin-top: 1rem !important;
            position: relative !important;
            z-index: 2 !important;
            min-height: 20rem;
            &.scrollmargin {
                scroll-margin-top: 5.5rem !important;
            }
        }
        
        &__zindex {
            z-index: 2 !important;
            position: relative !important;
        }
        &__formtoppadding {
            padding-top: 1rem !important;
        }

        &__inneritemmargin {
            margin-top: 2rem !important;
        }
    }
    &--search {
        &__checksmall {
            padding-left: 0.6875rem !important;
            margin-right: 2.6875rem !important;
        }

        &__checkbig {
            margin-right: 5.1875rem !important;
        }
    }
}
