@use "_colors";
@use "_fonts";

.accordionContainer {
    &.MuiAccordion-root:before {
        height: 0;
    }

    & .MuiAccordionSummary-content {
        width: -webkit-fill-available;
    }
    &.MuiAccordion-root {
        
        max-width: 100%;
        min-width: 100%;
        overflow-wrap: break-word !important;
    }
    .accordionTitle {
        &.MuiAccordionSummary-root {
            color: colors.$blue;
            position: relative;
            height: 4rem;
            padding: 0rem 1rem;
            @include fonts.medH4;
            &:hover {
                background-color: colors.$blue_light;
            }
            & .MuiAccordionSummary-expandIconWrapper {
                position:absolute;
                top: 1.7869rem;
                right: 1rem;
                padding: 0rem 1rem;
            }
        }
    }
    .miniBlueTitle {
        &.MuiAccordionSummary-root {
            color: colors.$blue;
            height: 3rem;
            padding: 0rem 0rem;
            @include fonts.medPara;
            & .MuiAccordionSummary-expandIconWrapper {
                position:absolute;
                top: 1.2681rem;
            }
    }
}
    
    .nopadding {
        &.MuiAccordionSummary-root {
             padding: 0 !important;
        }
    }
    .publicationPaddings {
        & .MuiAccordionSummary-expandIconWrapper {
            padding: 0rem !important;
       }
       &.MuiAccordionSummary-root {
        padding: .5rem !important;
   }
    }
    .miniTitle {
        &.MuiAccordionSummary-root {
            height: 3rem;
            @include fonts.medPara;
            & .MuiAccordionSummary-expandIconWrapper {
                position:absolute;
                top: 1.2681rem;
            }
        }
        &.MuiButtonBase-root {
            &.MuiAccordionSummary-root {
                &.Mui-expanded {
                    min-height: 3rem;
                }
            }
        }
    }
    .greenTitle {
        &.MuiAccordionSummary-root {
            color: colors.$green_dark;
        }
    }
    .typoTitle {
        width: 33%;    
        padding-right: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-shrink: 0;
        @include fonts.medPara;

        &.fullWidth {
            width: 100%;
        }
    }
    .typoSpecs {
        width: 67%;
        overflow-wrap: break-word;
        text-align: justify;
    }
    .typoCreated {
        @include fonts.medPara;
        color: colors.$gray_dark;
    }
    .accordionDetails {
        &.MuiAccordionDetails-root {
            padding: 0;
        }
    }
}

.contentBase {
    color: colors.$gray_dark;
    padding: 0.5rem 1rem;
    @include fonts.paraRegular;
    .typoLabel {
        width: 33%;
        flex-shrink: 0;
        @include fonts.medPara;
        color: colors.$gray_dark;
    }
}

.contentText {
    text-align: justify;
    text-justify: inter-word;
    padding-bottom: 1rem;
}

.contentFlex {
    padding: 0.75rem 1rem;
    display: flex;
    flex-direction: row;
}
