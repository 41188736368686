$blue: rgba(3, 73, 158, 1);
$blue_dark: rgba(2, 49, 107, 1);
$blue_bright: rgba(4, 96, 209, 1);
$blue_light: rgba(245, 249, 255, 1);
$blue_light_hover: rgba(245, 249, 255, 0.5);
$blue_medium: rgba(218, 221, 234, 1);

$green: rgba(58, 169, 53, 1);
$green_dark: rgba(41, 126, 38, 1);
$green_darkest: rgba(30, 92, 28, 1);
$green_light: rgba(218, 241, 217, 1);

$raspberry: rgba(209, 72, 90, 1);
$raspberry_dark: rgba(158, 54, 68, 1);
$raspberry_bright: rgba(255, 88, 110, 1);
$raspberry_light: rgba(255, 242, 244, 1);
$raspberry_medium: rgba(240, 194, 201, 1);

$sand: rgba(220, 201, 180, 1);
$sand_light: rgba(242, 239, 233, 1);
$sand_dark: rgba(128, 116, 104, 1);

$orange: rgba(234, 165, 61, 1);
$orange_bright: rgba(255, 200, 117, 1);
$orange_light: rgba(252, 241, 226, 1);
$orange_dark: rgba(166, 90, 33, 1);

$purple_bright: rgba(214, 49, 198, 1);
$purple_light: rgba(227, 98, 214, 1);
$purple_dark: rgba(112, 26, 104, 1);

$yellow: rgba(240, 223, 72, 1);
$yellow_light: rgba(255, 247, 178, 1);

$gray: rgba(209, 209, 209, 1);
$gray_light: rgba(245, 245, 245, 1);
$gray_medium: rgba(184, 184, 184, 1);
$gray_darker: rgba(117, 117, 117, 1);
$gray_dark: rgba(60, 60, 59, 1);

$white: #ffffff;