@use "_colors";
@use "_fonts";



.tooltipstyle {
    &.MuiTooltip-popper {
        padding-top: 0.3125rem;
    }
    & .MuiTooltip-tooltip {
        background: white;
        @include fonts.paraCaption;
        color: colors.$blue;
        border: 0.0313rem colors.$gray_darker solid;
        border-radius: 0.25rem;
    }
    &.error {
        & .MuiTooltip-tooltip {
            border-color: colors.$raspberry_dark;
            color: colors.$raspberry_dark;
        }
    }

    &.alternate {
        & .MuiTooltip-tooltip {
            border-color: colors.$blue;
            color: colors.$gray_dark;
        }
    }
}