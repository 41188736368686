@use "_colors";
@use "_styles";
@use "_fonts";

.textfieldLabel {
    &.MuiInputLabel-root {
        color: colors.$blue;
        @include fonts.medCaption;
        &.Mui-disabled {
            color: colors.$gray_dark;
        }
        &.Mui-error {
            color: colors.$raspberry_dark;
        }
        & .MuiInputLabel-asterisk {
            &.Mui-error {
                color: colors.$raspberry_dark;
            }
        }
    }
}

.inputStyle {
    &.MuiInput-root {
        height: 2rem;
        padding: 8px 12px;
        border-radius: 4px;
        border: 0.5px solid colors.$gray_dark;
        background: white;
        color: colors.$gray_dark;
        @include fonts.paraCaption;
        & input {
            &::placeholder {
                opacity: 1;
                color: colors.$gray_darker;
            }
        }
        &:hover {
            border: 1px solid colors.$blue;
            & input {
                &::placeholder {
                    opacity: 1;
                    color: colors.$gray_dark;
                }
            }
        }
        &.Mui-focused {
            border: 1px solid colors.$blue;
        }
    }
    &.Mui-disabled {
        &:hover {
            border: 0.5px solid colors.$gray_dark;
        }
        &.MuiInput-root {
            & input {
                opacity: 1;
                color: colors.$gray_dark;
                -webkit-text-fill-color: colors.$gray_dark;
                &::placeholder {
                    -webkit-text-fill-color: colors.$gray_dark;
                }
            }
        }
    }
}

.error {
    &.MuiInput-root {
        border: 0.5px solid colors.$raspberry_dark;
        &:hover {
            border: 0.5px solid colors.$raspberry_dark;
        }
        & input {
            color: colors.$raspberry_dark !important;
            &::placeholder {
                color: colors.$raspberry_dark !important;
            }
        }
        &.Mui-focused {
            border: 1px solid colors.$raspberry_dark;
        }
    }
}

.errorHelperText {
    position: absolute;
    @include fonts.medCaption;
    color: colors.$raspberry_dark;
}