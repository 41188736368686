@use '../../styles/colors';
@use '../../styles/fonts';

.projectName {
	@include fonts.boldH1;
	font-size: .75rem;
	color: colors.$blue_dark;
	line-height: .8125rem;
	margin-left: .75rem;
	margin-bottom: 0rem;
	word-break: break-all;
	max-width: 25rem;
}
.summary {
	@include fonts.firaSans;
	font-size: .75rem;
	color:black;
	margin-bottom: .125rem;
	line-height: .8125rem;
	word-break: break-all;
	max-width: 25rem;
}
.desc {
	@include fonts.firaSans;
	font-weight: 400;
	font-size: .75rem;
	width: 20.625rem;
	color:black;
	padding-bottom: 0.375rem;
	margin-top: .5625rem;
	line-height: .8125rem;
	word-break: break-all;
}
.workplace {
	@include fonts.firaSans;
	font-weight: 700;
	font-size: .75rem;
	color: black;
	line-height: .9rem;
	margin-left: .375rem;
	margin-bottom: .125rem;
	word-break: break-all;
	max-width: 25rem;
}

.personName {
	@include fonts.firaSans;
	font-weight: 700;
	font-size: 1rem;
	color: black;
	line-height: 1.2rem;
	margin-bottom: .1rem;
}

.header {
	@include fonts.firaSans;
	color: colors.$gray_dark;
	font-size: .6875rem;
	font-weight: 400;
	line-height: .75rem;
	margin: .125rem .75rem .125rem;
	padding-bottom:3.75rem;
}

.heading {
	@include fonts.firaSans;
	font-weight: 700;
	font-size: 1rem;
	color: black;
	line-height: 1.2rem;
	margin-bottom: .15rem;
	word-break: break-all;
	max-width: 25rem;
	padding-top: 1rem;
}

.plainText {
	@include fonts.firaSans;
	color: colors.$gray_dark;
	font-size: .6875rem;
	font-weight: 400;
	line-height: .75rem;
	margin: .125rem .75rem .125rem;
	word-break: break-all;
	max-width: 25rem;
}

.photo {
	content: "";
    width: 4.375rem;
	height: 4.375rem;
	border-radius: 3.125rem;
	display: flex;
	justify-content:center;
	margin-left: 1.25rem;
}



.cvRadio {
	&.radioPadding {
		padding-left: .625rem;
		padding-top: .5625rem;

		&.notop {
			padding-top: 0;
		}
	}
	&.radioFont {
		& .MuiFormLabel-root {
			font-size: 1rem;
		}
	}
}

.cvBase{
	padding-left: 6rem;
	padding-top: 1.4375rem;
	padding-right: 0rem;
	padding-bottom: 2.9375rem;
	background: white;
	width: 37.1875rem;
	min-height: 52.625rem;
	position: relative;
	&.cvSidebar {
		background-image:url('../../../assets/images/CV\ sidebar.svg');
		background-repeat: repeat-y;
	}
}

.cvfooter{
	padding-bottom: 2.9375rem;
}

.cvCheckbox{
	&.cellRow {
		display: flex;
		flex-direction: row;
		padding: 1.1875rem 1.3125rem 1.1875rem;
		height: 3.25rem;
		width: 100%;
		box-shadow: inset 0rem -0.0625rem 0rem rgba(3, 73, 158, 0.2);
		background: #FFFFFF;
	}
	&.cellLabel {
		@include fonts.paraCaption;
		color: colors.$gray_dark;
		padding: 1.125rem 1.3125rem;
		width: 10rem;
		height: 3.25rem;
	}
}

.topPadding {
	padding-top: 1rem;

}