@use '../styles/_colors.scss';
@use '../styles/_fonts.scss';


.myskill {
    &--grid {
        &__pagewrap {
            &.MuiGrid-root {
                height: 100%;
                width: 100%;
                padding: 5.5rem 4rem 8rem 20.875rem;
                }
        
                &.collapsed {
                    &.MuiGrid-root {
                        padding-left: 9rem;
                    }
                }
        }
    }

    &--font {
        &__personText {
            @include fonts.paraRegular;
            color: colors.$gray_dark;
        }
    

    &__titlefont {
        @include fonts.medPara;
        color: colors.$gray_dark;
    
        &.sini {
            color: colors.$blue;
        }
    
        &.osaajat {
            color: colors.$blue;
            margin-bottom: 1rem;
        }
        &.subTitle {
            @include fonts.medCaption;
            color: colors.$blue;
        }
    }

    &__multilinelabel {
        @include fonts.medCaption;
        color: colors.$blue;

    }
    &__multilinetext {
        @include fonts.paraCaption;
        color: colors.$gray_dark;
        &.roles {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        }
        &.title {
            @include fonts.medCaption;
            white-space: pre;
        }
    }
    &__regtext {
        @include fonts.paraCaption;
        color: colors.$gray_dark;
        word-break: keep-all;
        word-wrap: break-word;

    &.placeholder {
        color: colors.$gray_darker;
    }
    }
    }
}

