@use "_colors";
@use "_fonts";

.infoSnackbar {
	&.MuiAlert-standard {
        background-color: colors.$white;
        padding: 1rem;
        box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.1);
        align-items: center !important;
        & .MuiAlert-message {
            padding: 0;
            @include fonts.paraRegular;
            color: colors.$gray_dark;
            & .MuiAlertTitle-root {
                @include fonts.medPara
            }
        }
        & .MuiAlert-icon {
            margin-right: 1rem;
        }
    }
}
.infoSnackbar--blue{
    &.MuiAlert-standard{
        border-style: solid;
        border-color: #03499E;
        background-color: #f5f9ff;
        margin: 0;
        padding: 1rem;
        border-width: 1px !important;
        color: #03499E;
        & .MuiAlert-icon {
            margin-right: 1.5rem;
            padding: 0;
        }
        & .MuiAlert-message {
            padding: 0;
        }
        & .headertext-bold{
            align-items: center;
            color: #03499E;
            @include fonts.medCaption;
        }
        & .text2{
            @include fonts.paraLabel;
           margin-top: 0.25rem;
          
         }
    }
}








