@use '_colors';
@use '_fonts';

.navDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    .discussionNav {
        display: flex;
        flex-direction: row;
        padding: 0rem 0.5rem 0rem 0.5rem;
        color: colors.$blue_bright;
        & > div {
            margin: 1px;
            @include fonts.medPara;
            padding: 0.75rem 0.5rem;
            &:hover {
                @include fonts.paraRegular;
                color: colors.$blue_dark;
                background-color: colors.$blue_light;
            }
        }
        & .selectedNav {
            outline: 1px solid colors.$blue_bright;
            border-radius: 4px 4px 0px 0px;
        }
    }
}

.formContainer {
    padding: 1rem 1rem 1rem 1rem;
    .goalsDiv {
        padding-top: 1rem;
    }
}

.typoSpecs {
    word-break: break-all;
    white-space: pre-line;
}

.saveDeleteButtonsDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem 2rem;
    .saveButton {
        padding: 0rem 1rem;
    }
    .approveButton {
        padding-left: 1rem;
    }
}

.newDiscTitle {
    @include fonts.medH4;
    color: colors.$blue;
    padding: 1rem;
}

.fieldMargin {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.fieldPadding {
    padding: 1rem;
}

.horizontalPadding {
    padding: 0rem 1rem;
}

.discFormTitle {
    @include fonts.medPara;
    color: colors.$gray_dark;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.confirmSendDiv {
    border-top: 1px solid colors.$gray;
    text-align: center;
    padding: 1rem 0rem;
    .confirmSendHeader {
        color: colors.$blue;
        @include fonts.medPara;
        padding-bottom: 0.5rem;
    }
    .confirmSendButtons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .cancelConfirmSendButton {
            padding-right: 1rem;
        }
    }
}

.diffDiv {
    padding: 0.5rem 0.75rem;
}

.loaderDiv {
    text-align: center;
    background-color: white;
    padding: 2rem 0rem;
}

.greenText {
    color: colors.$green;
    text-decoration: underline;
    font-weight: bold;
}

.redText {
    color: colors.$raspberry_dark;
    text-decoration: underline;
    font-weight: bold;
}

.defaultText {
    color: colors.$gray_dark;
}

.spacediv {
    height: 2.5rem;
}
