@import "global";

.modal-container {
    width: 65%;
    counter-reset: 1;

    .four.wide.field {
        display: flex;

        button {
            margin-right: 1rem;
        }
    }

    .new-project-button-container {
        padding-top: 1rem;

        .add-button {
            margin-right: 1rem;
        }
    }

    .modal-toast--container {
        position: absolute;
        right: -375px;
    }

    .modal-header {
        background-color: $green-primary-default !important;
        color: $white !important;
        border: 1px solid $white;

        .modal-header--content {
            display: flex !important;
            justify-content: space-between;
            align-items: center !important;

            .modal-header--title {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .modal-header--close-button {
                background-color: white;
            }
        }
    }
}
