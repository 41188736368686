@use "_colors";
@use "_styles";
@use "_fonts";

.multilineLabel {
    &.MuiInputLabel-root {
        color: colors.$blue;
        @include fonts.medCaption;
        &.Mui-disabled {
            color: colors.$gray_dark;
            @include fonts.medCaption;
        }
        &.Mui-error {
            color: colors.$raspberry_dark;
        }
        & .MuiInputLabel-asterisk {
            &.Mui-error {
                color: colors.$raspberry_dark;
            }
        }
    }
}

.textArea {
    &.MuiInputBase-input {
        padding: 0.5rem 0.75rem;
    }
    &.resize {
        resize: vertical !important;
    }
}

.inputstyling {
    &.MuiInput-root {
        padding: 0 !important;
    }

    background: white !important;
    border-radius: 4px;
    & ::-webkit-scrollbar-thumb {
        background-color: colors.$gray_medium !important;
    }
    & ::-webkit-scrollbar-track {
        background: colors.$gray !important;
    }


    &.MuiInputBase-multiline {
        height: auto !important;
        @include fonts.paraCaption;
        color: colors.$gray_dark !important;
        border: 0.5px solid colors.$gray_dark;
        &:hover {
            border: 1px solid colors.$blue;
        }
        &.Mui-focused {
            border: 1px solid colors.$blue;
        }
        &.Mui-disabled {
            border: 0.5px solid colors.$gray_dark;
            &:hover {
                border: 0.5px solid colors.$gray_dark;
            }
        }
        & ::placeholder {
            opacity: 1;
            color: colors.$gray_darker !important;
        }
        &.Mui-error {
            border: 0.5px solid colors.$raspberry_dark !important;
            color: colors.$raspberry_dark !important;
            & ::placeholder {
                color: colors.$raspberry_dark !important;
            }
        }
    }
    & .MuiInputBase-input {
        &.MuiInput-input {
            &.Mui-disabled {
                -webkit-text-fill-color: colors.$gray_dark !important;
                &::placeholder {
                    -webkit-text-fill-color: colors.$gray_dark !important;
                }
            }
        }
    }
}
.helperbox {
    display: flex;
    justify-content: space-between;
}

.errortext {
    color: colors.$raspberry_dark;
    @include fonts.paraCaption;
}
.multiCounter {
    color: colors.$gray_dark;
    @include fonts.labelLight;
    text-align: end;
}

.counterError {
    color: colors.$raspberry_dark;
}

.counterDisabled {
    color: colors.$gray_dark;
}

.counterMedium {
    @include fonts.medCaption;
}

.counterZero {
    color: colors.$raspberry_dark;
    @include fonts.medCaption;
}
