@use "_colors";
@use "_fonts";

.radioComponent {
    & .MuiFormLabel-root {
        color: colors.$blue;
        @include fonts.medCaption;
        &.Mui-focused {
            color: colors.$blue;
        }
        &.Mui-disabled {
            color: colors.$gray_darker;
        }
    }
    .radioOption {
        color: colors.$gray_dark;
        & .MuiFormControlLabel-label {
            @include fonts.paraCaption;
            &.Mui-disabled {
                color: colors.$gray_darker;
            }
        }
        & .MuiRadio-root {
            &:hover {
                background-color: colors.$blue_light_hover;
            }
            &:active {
                background-color: colors.$blue_medium;
            }
        }
        & .MuiSvgIcon-root {
            height: 0.875rem;
            width: 0.875rem;
            color: colors.$blue;
        }
        & .Mui-disabled {
            & .MuiSvgIcon-root {
                color: colors.$gray_darker;
            }
        }
    }
}
