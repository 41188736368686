@use "_colors";
@use "_fonts";

.privacypolicy {
    &__page {
        background-color: colors.$gray_light;
        &__content {
            max-width: 100rem;
            padding-top: 2rem;
        }
    }
}