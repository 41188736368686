.homepage-list-header {
  padding: 0.2em;
  cursor: pointer;
}

.homepage-list-header-bold {
  padding: 0.2em;
  font-weight: 600 !important;
  cursor: pointer;
  color: #b23233 !important;
}

.neededskills-placeholder {
  width: fit-content;
}
.neededskills-list {
  vertical-align: middle;
}

.homepage-container {
  margin-top: 6em;
  position: relative;
}

.homepage-card-content {
  background-color: whitesmoke;
}

.homepage-grid-column {
  display: flex;
  align-items: center;
  padding-bottom: 0px;
}

.homepage-divider {
  background-color: #3baa34;
}

.homepage-grid {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepage-footer-privacy-policy {
  padding-top: 3px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-grid-column-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding-top: 0px;
  margin-left: 130px;
}

.homepage-footer-feedback-questions {
  white-space: nowrap;
  margin-left: 10px;
}
.homepage-footer-privacy-policy-link {
  white-space: nowrap;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.allneededskills-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 3em !important;
}

.all-needed-skills-container {
  width: 100%;
  padding-top: 1em;
}

.overflow-all-needed-skills-container {
  overflow-y: auto;
  max-height: 20em;
}

.homepage-allskills-button {
  position: absolute;
  bottom: 0;
}

.homepage-skills-header {
  margin-bottom: auto !important;
}

.list-item-skill {
  flex: 0 0 !important;
}

.dep-list-allskills {
  width: 30% !important;
}
