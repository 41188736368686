@use "_colors";
@use "_fonts";

.gdpr {
    &__dialog {
        & .MuiPaper-root {
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
            height: 15.5rem;
            width: 28rem;
        }
        & .MuiDialogTitle-root {
            @include fonts.medH4;
            background: linear-gradient(180deg, rgba(41, 126, 38, 0) 0%, rgba(41, 126, 38, 0.3) 100%), colors.$green;
            color: colors.$white;
            padding: 1rem;
        }
        &__content {
            &.MuiDialogContent-root {
                padding: 1rem !important;
                @include fonts.paraRegular;
            }
            &--clickable {
                text-decoration: underline;
                color: colors.$blue_bright;
                cursor: pointer;
            }
            &--policytitle {
                margin-top: 1.125rem;
                @include fonts.medCaption;
                color: colors.$blue;
            }
        }
        & .MuiDialogActions-root {
            padding: 1rem;
            &>:not(:first-of-type) {
                margin-left: 1rem;
            }
        }
    }
    &__pdf {
        border-radius: 0;
        &__title {
            &.MuiDialogTitle-root {
                margin: 0.5rem;
            }
            .crossbutton {
                position: absolute;
                right: 0.5rem;
                top: 0.75rem;
            }
        }
        & .MuiPaper-root {
            border-radius: 0;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
            &.MuiDialog-paper {
                max-width: 50rem;
                max-height: 97vh;
            }
        }
        &__container {
            overflow-x: hidden;
            overflow-y: auto;
            .react-pdf__Page__canvas {
                max-width: 100% !important;
                height: 100% !important;
            }
            &.MuiDialogContent-root {
                padding: 1rem !important;
            }
            &::-webkit-scrollbar {
                background-color: colors.$gray_light;
                width: 1rem;
            }
            &::-webkit-scrollbar-track {
                background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 0;
                border: 2px solid transparent;
                background-color: colors.$gray !important;
                background-clip: content-box !important;
            }
            &::-webkit-scrollbar-button {
                background-color: colors.$gray_light;
                background-size: 0.75rem 0.5156rem;
                background-repeat: no-repeat;
                height: 0.9rem;
            }
            &::-webkit-scrollbar-button:end:increment {
                background-position: center 2px;
                background-image: url("data:image/svg+xml;utf8,<svg width='12' height='8.25' transform='rotate(180)' viewBox='0 0 13 9' xmlns='http://www.w3.org/2000/svg'><path d='M0.5 8.25L6.5 0L12.5 8.25H0.5Z' fill='%23D1D1D1'/></svg>");
                &:hover {
                    background-image: url("data:image/svg+xml;utf8,<svg width='12' height='8.25' transform='rotate(180)' viewBox='0 0 13 9' xmlns='http://www.w3.org/2000/svg'><path d='M0.5 8.25L6.5 0L12.5 8.25H0.5Z' fill='%23B8B8B8'/></svg>");
                }
                &:active {
                    background-image: url("data:image/svg+xml;utf8,<svg width='12' height='8.25' transform='rotate(180)' viewBox='0 0 13 9' xmlns='http://www.w3.org/2000/svg'><path d='M0.5 8.25L6.5 0L12.5 8.25H0.5Z' fill='%23757575'/></svg>");
                }
            }
            &::-webkit-scrollbar-button:start:decrement {
                background-position: center 4px;
                background-image: url("data:image/svg+xml;utf8,<svg width='12' height='8.25' viewBox='0 0 13 9' xmlns='http://www.w3.org/2000/svg'><path d='M0.5 8.25L6.5 0L12.5 8.25H0.5Z' fill='%23D1D1D1'/></svg>");
                &:hover {
                    background-image: url("data:image/svg+xml;utf8,<svg width='12' height='8.25' viewBox='0 0 13 9' xmlns='http://www.w3.org/2000/svg'><path d='M0.5 8.25L6.5 0L12.5 8.25H0.5Z' fill='%23B8B8B8'/></svg>");
                }
                &:active {
                    background-image: url("data:image/svg+xml;utf8,<svg width='12' height='8.25' viewBox='0 0 13 9' xmlns='http://www.w3.org/2000/svg'><path d='M0.5 8.25L6.5 0L12.5 8.25H0.5Z' fill='%23757575'/></svg>");
                }
            }
            &:hover {
                &::-webkit-scrollbar-thumb {
                    border: 2px solid transparent !important;
                    background-clip: content-box;
                }
            }
            &--loading {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50rem;
                height: 97vh;
            }
        }
    }
}