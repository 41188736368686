@use '../styles/_colors.scss';
@use '../styles/_fonts.scss';

.projects {
    &--cancelbutton {
        margin-right: 1rem !important;
    }

    &--listitemtext {
        & .MuiTypography-root {
            @include fonts.paraCaption;
            color: colors.$gray_dark;
        }
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }
    &--listitemmargin {
        padding-bottom: 0 !important;
    }

    &--listitempadding {
        padding: 0 0 1rem 0 !important;
    }

    &--bottompadding {
        padding-bottom: 1rem !important;
    }

    &--unapprovedpadding {
        padding-bottom: 1rem !important;
        padding-top: 2rem !important;
    }

    &--projectheader {
        @include fonts.medH4;
        color: colors.$blue;
        padding-bottom: 2rem !important;
    }

    &--skillheader {
        @include fonts.medPara;
        padding-bottom: 1.5rem !important;
    }
    &--skillforme {
        @include fonts.medPara;
        color: colors.$blue;
    }

    &--checkboxmargin {
        margin-top: -2rem !important;
    }
}