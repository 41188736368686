@use "_colors";
@use "_fonts";

.switchLabel {
    & .MuiFormControlLabel-label {
        color: colors.$gray_dark;
        @include fonts.paraCaption;
        &.Mui-disabled {
            color: colors.$gray_darker !important;
        }
    }
}

.OPSwitch {
    & .MuiSwitch-track {
        background-color: colors.$blue;
    }
    & .MuiSwitch-switchBase {
        &.Mui-checked {
            & + .MuiSwitch-track {
                opacity: 1;
                background-color: colors.$blue;
            }
            &:hover {
                background-color: rgba(4, 96, 209, 0.1);
            }
            &:active {
                background-color: rgba(4, 96, 209, 0.25);
            }
        }
        &:hover {
            background-color: rgba(4, 96, 209, 0.1);
        }
        &:active {
            background-color: rgba(4, 96, 209, 0.25);
        }
    } 
    & .MuiSwitch-thumb {
        background-color: colors.$blue_medium;
    }
    & .Mui-disabled {
        & .MuiSwitch-thumb {
            background-color: colors.$gray;
        }
        &.Mui-checked + .MuiSwitch-track {
            opacity: 1;
            background-color: colors.$gray_darker;
        }
        & + .MuiSwitch-track {
            opacity: 1 !important;
            background-color: colors.$gray_darker;
        }
    }
}
