@use "_colors";
@use "_fonts";

.numberfieldLabel {
    &.MuiInputLabel-root {
        color: colors.$blue;
        @include fonts.medCaption;
        &.Mui-disabled {
            color: colors.$gray_dark;
        }
        &.Mui-error {
            color: colors.$raspberry_dark;
        }
    }
}

.numberInputStyle {
    &.MuiInput-root {
        background-color: white;
        border-radius: 4px;
        border: 0.5px solid colors.$gray_dark;
        height: 2rem;
        @include fonts.paraCaption;
        color: colors.$gray_dark;
        &:hover {
            border: 1px solid colors.$blue;
            &.Mui-disabled {
                border: 0;
            }
        }
        &.Mui-focused {
            border: 1px solid colors.$blue;
            background-color: colors.$blue_light;
        }
        &.Mui-error {
            color: colors.$raspberry_dark;
            border: 1px solid colors.$raspberry_dark;
            background-color: colors.$white;
        }
        & .MuiInput-input {
            padding: 8px 12px;
            &.Mui-disabled {
                -webkit-text-fill-color: colors.$gray;
            }
        }
    }
    &.Mui-disabled {
        background-color: colors.$gray;
        border: 0;
        & input[type=number]::-webkit-inner-spin-button {
            display: none;
        }
        & input[type=number] {
            -moz-appearance:textfield;
        }
    }
}

.emptyInput {
    &.MuiInput-root {
        color: colors.$gray_darker;
        &:hover {
            color: colors.$gray_dark;
        }
    }
    & input[type=number]::-webkit-inner-spin-button {
        display: none;
    }
    & input[type=number] {
        -moz-appearance:textfield;
    }
}

.filledInput {
    & input[type=number]::-webkit-inner-spin-button {
        display: none;
    }
    & input[type=number] {
        -moz-appearance:textfield;
    }
}

.iconSpan {
    display: flex;
    flex-direction: column;
    justify-content: Center;
    align-items: Center;
}

.endArrows {
    margin-right: 0.75rem;
}

.upArrow, .downArrow {
    &.MuiIconButton-root {
        border-radius: 2px;
        margin: 2px;
        &:hover {
            background-color: colors.$gray_medium;
        }
        &:active {
            background-color: colors.$blue;
        }
    }
}

.errorArrow {
    &.MuiIconButton-root {
        &:active {
            background-color: colors.$raspberry_dark;
        }
    }
}

.upArrow {
    &.MuiIconButton-root {
        padding: 3.33px 1.68px 1.23px 1.68px;
    }
}

.downArrow {
    &.MuiIconButton-root {
        padding: 1.23px 1.68px 3.33px 1.68px;
    }
}

.helperText {
    @include fonts.medCaption;
    color: colors.$raspberry_dark;
}
