@use "_colors";

.loadingcircle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.saving-spinner-container {
    display: flex;
    gap: 0.5rem;
    min-height: 2rem;
    max-height: 2.5rem;
    align-items: center;
    color: #000 !important;
    border: 1px solid colors.$green;
    padding-inline: 0.75rem;
    padding-block: 0.25rem;
    border-radius: 0.25rem;
    margin-left: 2rem;


    &.error {
        border-color: colors.$raspberry_dark;
    }
}

.saving-spinner {
    color: rgba(58, 169, 53, 1) !important;
    max-height: 1rem;
    max-width: 1rem;
}