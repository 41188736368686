@use '_colors';
@use '_fonts';

.th {
    border-right: 2px solid colors.$gray !important;  
}

.ellipsis {
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &_bolded {
        font-weight: 700 !important;
    }
}


.backdrop {
    z-index: 2 !important;
}


.newbase {
    &.MuiTable-root {
        background-color: white;
        table-layout: fixed;
    }
    & .MuiTableCell-head {
        @include fonts.medPara;
        color: colors.$blue;
        border-bottom: 0.5px solid #03499E;
        -webkit-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none;
        user-select: none;
    }

    & .MuiTableRow-root {
        height: 3.25rem;
        max-height: 3.25rem;
    }


    & .MuiTableCell-body {
        @include fonts.paraCaption;
        color: colors.$gray_dark;
        border: 0 !important;
        box-shadow: inset 0px -1px 0px rgba(3, 73, 158, 0.2);
    }
    &.noborder {
        & .MuiTableCell-body {
            box-shadow: none;
        }
    }

    &.underline {
        & .MuiTableRow-root {
            &:hover{
            background-color: colors.$white;
            box-shadow: inset 0px -1px 0px #03499E;
            }
        }
    }

    &.nopadding {
        & .MuiTableCell-body {
            padding: 0;
        }
    }


    & .MuiTableRow-root {
        &:hover{
            background-color: colors.$blue_light;
        }
    }

    & .MuiTableRow-head {
        &:hover {
            background-color: white;
        }
    }
    & .MuiTableSortLabel-root{
        width: 100%;
        justify-content: space-between !important;
    }
    & .MuiTableSortLabel-root.Mui-active, .MuiTableSortLabel-root:hover {
        color: colors.$blue;
    }
}
.MuiTableSortLabel-root .overflow {
    white-space: pre-wrap;
    text-overflow: initial !important;
    word-wrap: break-word !important;
    overflow: initial;
    hyphens: manual;
}
.activebackground {
    &.MuiTableRow-root {
        background-color: colors.$blue_light;
    }
}

.collapsenohover {

    &.MuiTableRow-root {
        height: 0;
    }

    &.MuiTableRow-root {
        &:hover{
            background-color: colors.$white;
        }
    }
}

.base {

    &.MuiTable-root {
        border: 0;
        & .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus {
            outline: 0;
        }
        & .MuiDataGrid-iconSeparator {
            color: colors.$gray;
        }
        & .MuiDataGrid-footerContainer {
            border: 0;
        }

        & .MuiDataGrid-columnHeader {
            @include fonts.medPara;
            color: colors.$blue;
            border: 0;
            -webkit-user-select: none; 
            -moz-user-select: none; 
            -ms-user-select: none;
            user-select: none; 
        
            &:focus-within {
                outline: 0;
            }

            & .MuiDataGrid-columnHeaderTitleContainerContent {
                width: 95%;
            }
        }



        & .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator {
            display: none;
          }

        & .MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-selected{
            background-color: colors.$blue_light;
        }

        & .MuiDataGrid-main {
            background-color: colors.$white;
        }
        & .MuiDataGrid-columnHeaders {
            border: 0;
            box-shadow: inset 0px -1px 0px colors.$blue;
        }
    
    & .MuiDataGrid-cell {
        @include fonts.paraCaption;
        color: colors.$gray_dark;
        border: 0 !important;
        box-shadow: inset 0px -1px 0px rgba(3, 73, 158, 0.2); 
    }
    }
}



