@use "_colors";
@use "_fonts";

.exitconfirmation {
    &__dialog {
        & .MuiPaper-root {
            z-index: 2000;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
            height: 15.5rem;
            width: 28rem;
        }
        & .MuiDialogTitle-root {
            @include fonts.medH4;
            background: linear-gradient(180deg, rgba(41, 126, 38, 0) 0%, rgba(41, 126, 38, 0.3) 100%), colors.$green;
            color: colors.$white;
            padding: 1rem;
        }
        & .MuiDialogContent-root {
            padding: 1rem !important;
            @include fonts.paraRegular;
        }
        & .MuiDialogActions-root {
            padding: 1rem;
            &>:not(:first-of-type) {
                margin-left: 1rem;
            }
        }
        &.MuiDialog-root {
            z-index: 1999 !important;
        }
    }
}
