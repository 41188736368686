@use "_colors";
@use "_fonts";

.newGoalForm {
    .newGoalHeader {
        border-top: 1px solid colors.$gray;
        padding: 1rem;
        @include fonts.medH4;
        color: colors.$blue;
    }
    .formFields {
        padding: 1rem;
        .bottomPadding {
            padding-bottom: 1rem;
        }
    }
    .confirmDiv {
        border-top: 1px solid colors.$gray;
        text-align: center;
        padding: 1rem 0rem;
        .confirmHeader {
            color: colors.$blue;
            @include fonts.medPara;
            padding-bottom: 0.5rem;
        }
        .confirmButtons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .cancelConfirmButton {
                padding-right: 0.5rem;
            }
        }
    }
    .buttonsDiv {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 1rem 2rem;
        .deleteButton {
            padding: 0rem 3rem 0rem 0rem;
        }
        .cancelButton {
            padding: 0rem 1rem 0rem 0rem;
        }
    }
}

.grayDivider {
    border-bottom: 1px solid colors.$gray;
}
