.partner-infocontainer {
    padding-top: 2rem !important;
    margin: 0 !important;
}

.partner-list-link{
    text-decoration: none !important;
}
.partner-search{
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
    margin-left: 12px;
}
.partner-list{
    background-color: white;
    align-items: center;
    width: 100%;
    margin-top: 0 !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
    :hover{
        background-color:#f5f9ff;
        color: #3C3C3B;
    }
    :active{
        background-color: #DADDEA;
    }
  
}
.partner-list-name{
    color: #3C3C3B;
    line-height: 24px !important;
    align-items: center;
    font-weight: 500;
    font-size: 16px !important;
    padding-bottom: 8px;
    letter-spacing: 0.04em !important;
   padding-top: 8px;
   padding-left: 16px;
   
  
}
.partner-list-edited{
    align-items: center;
    padding-top: 4px;
    padding-left: 16px;
    letter-spacing: 0.04em !important;
}
.partner-last-updated-text{
    color: #3C3C3B;
    align-items: center;
    font-weight: 400 !important;
    letter-spacing: 0.04em !important;
}

.partner-list-loadmore{
    padding-top: 16px !important;
    background-color: transparent;
}
