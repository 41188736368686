@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&display=swap');

body {
  font-size: 14px;
  line-height: 1.4285em;
  margin: 0;
  font-family: 'Fira Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245, 245, 245);
  overflow-x: hidden;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    }
    
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    }
    
  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.25);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
    }
    
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0, 0, 0.3);
    }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
}
}

.twoRowText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.toast-container {
  font-family: 'Fira Sans',
    sans-serif;
    font-size: 0.8313rem;
    line-height: 1rem;
    color: #ffffff;
    width: 20rem;
    height: 3rem;
    &.success {
      background: #3AA935 !important;
    }
    &.error {
      background: #D1485A !important;
    }
}

html {
  height: unset;
  font-size: 100%;
}

.menuItem {
  color: white !important;
}

.menuBar {
  background-color: #207a34 !important;
  font-size: 1.1em !important;
}

.active rect {
  opacity: 0.05;
}

.logo:hover rect {
  opacity: 0.03;
}

.logoNoLightVersion:hover #light {
  fill: #ffd600;
}

.wrapped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.required:after {
  content: ' *';
  display: inline;
  color: red;
}

/* Same as .required but forces the * to stay at same line */
.textWrapperRequired {
  position: relative;
}
.textWrapperRequired:after {
  position: absolute;
  right: -0.7em;
  bottom: 0;
  color: red;
  content: ' *';
}

.overflowText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.overflowTextDontBreakOut {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.overflowTableContainer {
  max-height: 55vh;
  overflow-y: auto;
}

.overflowTableContainerMedium {
  max-height: 40vh;
  overflow-y: auto;
}

.overflowTableContainerSmall {
  max-height: 27vh;
  overflow-y: auto;
}

.overflowSearchTableContainer {
  overflow-y: auto;
}

.overflowListContainer {
  max-height: 7vh;
  overflow-y: auto;
}

.overflowTableCellContainer {
  max-height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.blurring.dimmed.dimmable > :not(.dimmer).popup {
  filter: none;
}

.frontpage {
  margin-left: 30px;
  margin-right: 30px;
}

.boldLabel {
  font-weight: bold;
}

.selectable tbody tr {
  cursor: pointer;
}

.boldTableRow td {
  font-weight: bold;
}

.frontpageBoxes {
  background-color: whitesmoke !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.frontpageCardContentAlign {
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}

.frontpageList {
  height: 100%;
  display: flex !important;
  flex-direction: column !important;
}

.frontpageList .item {
  flex: 1 !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.counter {
  float: right !important;
  font-size: 0.8em !important;
}



.positive {
  background-color: #207a34 !important;
  color: white !important;
}

.jodit-placeholder {
  z-index: 0 !important;
}

p {
  margin: 0em 0em 1em;
  line-height: 1.4285em;
  
}

p:last-child {
  margin-bottom: 0;
}
p:first-child {
  margin-top: 0;
}