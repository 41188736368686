@use "_colors";
@use "_fonts";

.help {
    &--titlefont {
        @include fonts.medPara;
        color: colors.$gray_dark;
    }  
    &--metafont {
        @include fonts.paraCaption;
        color: colors.$gray_dark;


        &.bold {
            font-weight: 500;
        }
    }

    &--listmargin {
        margin: 0;
    }

    &--linkunderline {
        text-decoration: underline;
    }
}
