@use "_colors";
@use "_fonts";

.comboBoxLabel {
    &.MuiInputLabel-root {
        color: colors.$blue;
        @include fonts.medCaption;
        &.Mui-disabled {
            color: colors.$gray_dark;
        }
        &.Mui-error {
            color: colors.$raspberry_dark;
        }
    }
}

.chipStyling {
    &.MuiChip-root {
        background-color: colors.$white;
        padding: 4px 8px;
        margin: 0.1rem 0.5rem 0.1rem 0rem;
        border-radius: 16px;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        @include fonts.paraCaption;
        color: colors.$gray_dark !important;
        &.Mui-focusVisible {
            background-color: colors.$white;
            border: 0.5px solid colors.$blue_dark;
        }
    }
}

.combobox {



    &.MuiAutocomplete-root {
        & .MuiAutocomplete-inputRoot {
            padding-right: 3.0013rem !important;
            padding-left: 1rem !important;
            min-height: 2rem;

        }
        & .MuiInput-root {
            margin-left: 1px;
            background: colors.$white;
            border-radius: 4px;
            outline: 0.5px solid colors.$gray_dark;
            &:hover {
                outline: 1px solid colors.$blue;
                &:disabled{ 
                    outline: 0;
                }
            }
            &.Mui-error {
                outline: 1px solid colors.$raspberry_dark;
                &:hover {
                    outline: 1px solid colors.$raspberry_dark;
                }
            }
            &.Mui-disabled {
                background-color: colors.$gray;
                border: 0;
                outline: 0;
                border-radius: 4px;
            }
            & .MuiInput-input {
                color: colors.$gray_dark;
                @include fonts.paraCaption;
                padding: 0;
                &::-webkit-input-placeholder {
                    opacity: 1;
                    @include fonts.paraCaption;
                    color: colors.$gray_darker;
                }
                &:hover {
                    &::-webkit-input-placeholder {
                        opacity: 1;
                        color: colors.$gray_dark;
                    }
                }
            }
        }
    }
    & .MuiAutocomplete-endAdornment {
        position: absolute;
        right: 1rem;
    }
    & .MuiAutocomplete-clearIndicator {
        padding-right: 1rem;
        &:hover {
            background: transparent !important;
        }
    }
    & .MuiAutocomplete-popupIndicator {
        &:hover {
            background-color: transparent !important;
        }
        &:active {
            background-color: transparent !important;
        }
        & .MuiTouchRipple-root {
            display:none;
        }
    }
}

.open {
    border-radius: 4px 4px 0px 0px;
    background: colors.$white;
    outline: 1px solid colors.$blue;
    &:hover {
        border-radius: 4px 4px 0px 0px;
    }
}

.paperStyle {
    & .MuiAutocomplete-listbox {
        padding: 0;
        background-color: colors.$white !important;
        color: colors.$gray_dark !important;
        border-radius: 0 0 4px 4px;
        @include fonts.paraCaption;
        outline: 1px solid colors.$blue;
        margin: -0.0781rem;
        & .MuiAutocomplete-option  {
            &:hover, &.Mui-focused {
                background-color: colors.$blue_light !important;
            }
            &.MuiAutocomplete-option[aria-selected=true] {
                background-color: colors.$blue_medium !important;
                color: colors.$blue_dark !important;
            }
        }
        & li:last-child {
            border-radius: 0 0 4px 4px;
        }
    }
    & .MuiAutocomplete-noOptions {
        color: colors.$gray_dark;
        background-color: colors.$white;
        @include fonts.paraCaption;
        border-radius: 0px 0px 4px 4px;
        outline: 1px solid colors.$blue;
        margin: -0.0781rem;
    }
}

.multiOptions {
    &.MuiAutocomplete-root {
        & .MuiAutocomplete-inputRoot {
            padding: 0.15rem 3.0013rem 0.15rem 1rem !important;
        }
    }
}

.comboboxerrortext {
    color: colors.$raspberry_dark;
    @include fonts.medCaption;
    position: absolute;
}
